import React from "react";
import { useNavigate } from "react-router-dom";
import Bgg from "../assets/bgg.jpg";
import logo from "../assets/Logo.png";
import G_perf from "../assets/g-performance/g-perf.png";
import G_Vendor from "../assets/g-vendor/g-vendor.png";

// Liste des projets
const projectsList = [
  { id: 0, name: "G-Performance", logo: G_perf, path: "/gperf/login" },
  { id: 1, name: "G-Vendor", logo: G_Vendor, path: "" },
  // { id: 1, name: "G-Vendor", logo: G_Vendor, path: "/gvendor/login" },
];

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <div className="bg-black w-full flex items-center h-screen">
      {/* Partie gauche */}
      <div
        style={{ backgroundImage: `url(${Bgg})` }}
        className="bg-cover w-8/12 h-screen flex flex-col justify-between p-20"
      >
        {/* En-tête */}
        <div className="flex items-center">
          <img src={logo} alt="logo" className="w-10 h-10 rounded-xl" />
          <h1 className="text-white font-bold text-2xl ml-2">GWS</h1>
        </div>

        {/* Bas */}
        <h1 className="text-white text-9xl font-bold text-center">
          Bienvenue sur GWS Admin
        </h1>
      </div>

      {/* Partie droite */}
      <div className="flex flex-col space-y-6 w-full max-w-md mx-auto">
        <h2 className="text-2xl text-white font-bold text-center">
          Connectez-vous à un projet
        </h2>

        <div className="grid grid-cols-3 sm:grid-cols-3 gap-4 bg-transparent bg-opacity-50 rounded-xl p-6">
          {projectsList.map((item) => (
            <button
              key={item.id}
              title={item.name}
              className={`flex flex-col items-center justify-center p-4 bg-white bg-opacity-10 rounded-xl shadow-lg transition duration-300 ease-in-out ${
                item.path
                  ? "hover:bg-opacity-20 hover:scale-105"
                  : "opacity-40 cursor-not-allowed"
              }`}
              onClick={() => item.path && navigate(item.path)}
              disabled={!item.path}
            >
              <img
                src={item.logo}
                alt={item.name}
                className="w-16 h-16 object-contain mb-2"
              />
              <span className="text-white text-sm font-medium text-center">
                {item.name}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
